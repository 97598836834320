import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { AppModule } from '@app/outdoorPro/app.module';
import { environment } from '@env/outdoorPro/environment';
import {
  init,
  showReportDialog,
  browserTracingIntegration,
  browserProfilingIntegration,
  makeBrowserOfflineTransport,
  makeFetchTransport,
} from '@sentry/angular';
import { captureConsoleIntegration, httpClientIntegration, reportingObserverIntegration } from '@sentry/browser';
/*
 * Entry point of the application.
 * Only platform bootstrapping code should be here.
 * For app-specific initialization, use `app/app.component.ts`.
 */

init({
  dsn: environment.sentryDSN,
  beforeSend(event, hint) {
    if (hint.originalException.toString().indexOf('FirebaseError: Missing or insufficient permissions') > -1) {
      // Check if it is an exception, and if so, show the report dialog
      if (event.exception) {
        showReportDialog({ eventId: event.event_id });
      }
    }
    return event;
  },
  integrations: [
    browserTracingIntegration(),
    captureConsoleIntegration({ levels: ['error'] }),
    httpClientIntegration(),
    reportingObserverIntegration({
      types: ['crash', 'deprecation', 'intervention'],
    }),
    browserProfilingIntegration(),
  ],
  tracePropagationTargets: [
    'localhost',
    ...environment.productionUrls,
    environment.stagingUrlsRegex,
    environment.previewUrlsRegex,
  ],
  enableTracing: true,
  tracesSampleRate: 1.0,
  release: `${environment.sentryProject}@${environment.version}`,
  environment: environment.envName,
  transport: makeBrowserOfflineTransport(makeFetchTransport),
});

if (environment.production) {
  enableProdMode();
}

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .catch((err) => console.error(err));
